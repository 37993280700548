import * as React from "react"
import { graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLink } from "@fortawesome/free-solid-svg-icons"
import ReactEmbedGist from "react-embed-gist"
import Layout from "../components/layout"
import Seo from "../components/seo"

const isBrowser = typeof window !== "undefined"

const TFM = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  if (!isBrowser) {
    return null;
  }

  return (
    <Layout location={location} title={siteTitle}>
      <header>
        <div id="tfm">
          <h2 className="font-semibold text-2xl">
            <a className="text-slate-500 italic" href="#tfm">
              #.
            </a>{" "}
            Trabajo de Fin de Máster para el MASW 23/24
          </h2>
          <p>
            Si llegaste aquí, es porque participaste de mi exposición de los resultados del <strong>Trabajo de Fin de Máster</strong> para el <strong>Máster en Desarrollo de Aplicaciones y Servicios Web</strong> en la <a href="https://www.universidadviu.com/es/" title="Ir a la universidad" target="_blank" rel="noreferrer">Universidad Internacional de Valencia</a>.
          </p>

          <header className="mt-6" id="video">
            <h2 className="font-semibold text-lg">
              <a className="text-slate-500 italic" href="#video">
                #.
              </a>{" "}
              Vídeo de la solución
            </h2>

            <p>
              He publicado un vídeo en YouTube para que puedas ver la solución en acción. Puedes verlo en el siguiente enlace:
            </p>

            <div className="rounded shadow-lg bg-white p-3 text-xs">
              <iframe className="youtube-video" src="https://www.youtube.com/embed/KKtVJaimIU8?si=LAvRMb-0VKAX3NSC" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

              <p className="mt-5 mb-0">
                <a href="https://www.youtube.com/watch?v=KKtVJaimIU8" className="text-slate-500 hover:text-slate-700" target="_blank" rel="noreferrer">
                  Ver en YouTube <FontAwesomeIcon icon={faLink} />
                </a>
              </p>
            </div>
          </header>

          <header className="mt-6" id="presentacion">
            <h2 className="font-semibold text-lg">
              <a className="text-slate-500 italic" href="#presentacion">
                #.
              </a>{" "}
              Slides de la presentación
            </h2>

            <p>
              Si deseas repasar los slides de la presentación. Puedes verlos en el siguiente enlace:
            </p>

            <div className="rounded shadow-lg bg-white p-3 text-xs">
              <iframe src="https://drive.google.com/file/d/1ApsyizWeQlKjL0IwnmfcE8n3DbBxPadW/preview" className="youtube-video" allow="autoplay"></iframe>

              <p className="mt-5 mb-0">
                <a href="https://drive.google.com/file/d/1ApsyizWeQlKjL0IwnmfcE8n3DbBxPadW/view" className="text-slate-500 hover:text-slate-700" target="_blank" rel="noreferrer">
                  Ver en Google Drive <FontAwesomeIcon icon={faLink} />
                </a>
              </p>
            </div>
          </header>

          <hr />

          <div className="rounded shadow-lg bg-white p-3 text-xs">
            <p className="text-lg font-semibold">¡Gracias por ofrecerme tu tiempo para escuchar mi presentación!</p>
            <p>Espero que te hayas divertido escuchando mi presentación tanto como yo disfruté haciendo este TFM.</p>
            <p>Si tienens alguna duda, comentario, sugerencia o simplemente quieres charlar, no dudes en contactarme. Puedes hacerlo a través del correo electrónico <a href="mailto:me@demogar.com?subject=TFM%20VIU" className="text-slate-500 hover:text-slate-700">me@demogar.com</a>.</p>
          </div>
        </div>
      </header>
    </Layout>
  )
}

export default TFM

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="TFM VIU" />

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    },
  }
`
